
import Vue, { PropType } from "vue";

import { Lesson } from "@prestonly/preston-common";
import { DialogCloseType, DialogI } from "@/types/dialog";

import BaseDialog from "@/components/dialog/BaseDialog.vue";
import TagSearchTooltip from "@/components/TagSearchTooltip.vue";
import TagChips from "@/components/base/TagChips.vue";
import SearchTextField from "@/components/form/inputs/SearchTextField.vue";

declare interface Data {
  loading: boolean;
  search: string;
  selectedLessons: Lesson[];
}

export default Vue.extend({
  name: "AttachExistingLessonDialog",
  components: {
    SearchTextField,
    BaseDialog,
    TagChips,
    TagSearchTooltip,
  },

  props: {
    dialog: {
      type: Object as PropType<DialogI>,
      required: true,
    },
  },

  data(): Data {
    return {
      loading: true,
      search: "",
      selectedLessons: [],
    };
  },

  computed: {
    lessons(): Lesson[] {
      const lessons = this.$store.getters["lesson/getSearchResults"];
      const selectedLessonIds = this.selectedLessons.map((l) => l._id);
      return lessons.filter((lesson) => !selectedLessonIds.includes(lesson._id));
    },
  },

  methods: {
    selectLesson(lesson: Lesson) {
      this.selectedLessons.push(lesson);
    },
    unselectLesson(lesson: Lesson) {
      this.selectedLessons = this.selectedLessons.filter((selected: Lesson) => selected._id !== lesson._id);
    },
    async getLessons(): Promise<void> {
      this.loading = true;
      let filters = "";
      if (this.search) {
        filters = `$search:${this.search}`;
      }
      await this.$store.dispatch("lesson/getList", {
        filters,
        limit: 50,
        page: 1,
      });
      this.loading = false;
    },
    submitForm() {
      this.dialog.close({
        type: DialogCloseType.SUBMITTED,
        payload: {
          lessons: this.selectedLessons,
        },
      });
    },
  },
});
